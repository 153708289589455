import React from 'react';
import { connectStats } from 'react-instantsearch-dom';
// import { formatNumber } from '../components/utils';

const SaveFiltersMobile = ({ nbHits, onClick }) => (
  <button className="button button-primary" onClick={onClick}>
    Ver resultados
  </button>
);

export default connectStats(SaveFiltersMobile);
