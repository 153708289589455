import React from 'react';

const AlgoliaSvg = () => (
  <a href="/" aria-label="ARMOIRE">
    <img
      // src="https://i.postimg.cc/Y27FSdYw/gold-pineapple-png-1-Transparent-Images-removebg-preview-2-1.png"
      src="/logo.png"
      width="180"
      alt=""
    ></img>
  </a>
);

export default AlgoliaSvg;
