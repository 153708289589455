import React from 'react';
import { db } from '../firebaseConfig';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { firebaseApp } from '../firebaseConfig';
import withFirebaseAuth from 'react-with-firebase-auth';

import Heart from 'react-animated-heart';

const publishLike = (data) => {
  const { id, user, isLiked, insights } = data;
  var batch = db.batch();
  const likesItem = db
    .collection('items')
    .doc(id)
    .collection('likes')
    .doc(user.uid);

  if (isLiked) {
    batch.delete(likesItem);
    const likesUser = db
      .collection('users')
      .doc(user.uid)
      .collection('likes')
      .doc(id);
    batch.delete(likesUser);
  } else {
    batch.set(likesItem, {
      currentTime: firebase.firestore.FieldValue.serverTimestamp(),
    });
    insights('convertedObjectIDsAfterSearch', {
      eventName: 'Product Liked',
      userToken: user.uid,
    });
    batch.set(
      db.collection('users').doc(user.uid).collection('likes').doc(id),
      {
        currentTime: firebase.firestore.FieldValue.serverTimestamp(),
      }
    );
  }
  batch.commit();
};

const Likes = (props) => {
  const [likes, setLikes] = React.useState([]);

  const postLike = (e) => {
    e.preventDefault();
    publishLike({
      id: props.id,
      user: props.user,
      isLiked: likes.includes(props.user.uid),
      insights: props.insights,
    });
  };
  React.useEffect(() => {
    db.collection('items')
      .doc(props.id)
      .collection('likes')
      .onSnapshot((snapshot) => {
        setLikes(snapshot.docs.map((doc) => doc.id));
      });
  }, [props.id]);

  return (
    <div className="likes">
      <div className="likes__section">
        <Heart
          isClick={props.user ? likes.includes(props.user.uid) : false}
          onClick={
            props.user
              ? postLike
              : () =>
                  alert(
                    'Para guardar como favorito los productos que selecciones, deberás registrarte previamente como usuario de Armoire.'
                  )
          }
        />
        <span>{likes.length} likes</span>
      </div>
    </div>
  );
};

const firebaseAppAuth = firebaseApp.auth();

const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
};

export default withFirebaseAuth({
  providers,
  firebaseAppAuth,
})(Likes);
