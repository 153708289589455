import React, { Component } from 'react';

export default class Terminos extends Component {
  render() {
    return (
      <main role="main">
        <div class="generic-pages">
          <div class="generic-pages__wrapper">
            <h1>TERMINOS Y CONDICIONES</h1>

            <h2>GENERALIDADES</h2>

            <p>
              Los t&eacute;rminos &ldquo;Armoire&rdquo;, &ldquo;nosotros&rdquo;
              y &ldquo;nuestro&rdquo; se refieren a Armoire. El t&eacute;rmino
              &ldquo;usted&rdquo; se refiere al usuario que acceder&aacute; a
              trav&eacute;s de la p&aacute;gina web&nbsp;
              <a href="www.armoire.com.uy">www.armoire.com.uy</a>.
            </p>
            <p>
              Armoire es un sitio web que busca dar visibilidad en un solo lugar
              a las diferentes opciones con las que cuenta el consumidor
              uruguayo a la hora de elegir productos relacionados con la
              industria de la vestimenta. En consecuencia, Armoire no es una
              p&aacute;gina web de venta o comercializaci&oacute;n de productos.
              La compra del producto elegido por usted, se har&aacute;
              directamente entre usted y el tercero mencionado y se
              concretar&aacute; en la p&aacute;gina web de propiedad de este
              &uacute;ltimo.
            </p>
            <p>
              Los presentes t&eacute;rminos y condiciones son de car&aacute;cter
              obligatorio y vinculante y usted deber&aacute; aceptarlos de
              continuar en la utilizaci&oacute;n de la presente p&aacute;gina
              web. Si usted no acepta los presentes T&eacute;rminos y
              condiciones, deber&aacute; abstenerse de utilizar el sitio.
            </p>
            <p>
              Al aceptar los t&eacute;rminos y condiciones, usted declara y
              garantiza que: i) es mayor de edad o se encuentra debidamente
              representado y adem&aacute;s es el titular que ingres&oacute; a la
              p&aacute;gina, &nbsp;ii) acepta expresamente la recepci&oacute;n
              de estos t&eacute;rminos y condiciones, iii) posee plena capacidad
              para celebrar contratos en un todo de acuerdo al C&oacute;digo
              Civil Uruguayo.
            </p>

            <h2>LOS PRODUCTOS</h2>

            <p>
              Sobre los productos que usted podr&aacute; visualizar, intentamos
              ser lo m&aacute;s precisos posible en la descripci&oacute;n de los
              mismos, a tales efectos actualizamos con frecuencia el contenido
              manifiesto. Asimismo no podemos garantizar que los detalles y
              descripciones, as&iacute; como las im&aacute;genes sean exactas ni
              correspondan a la realidad del producto. Si usted necesita una
              descripci&oacute;n exacta o informaci&oacute;n adicional,
              as&iacute; como para conocer la disponibilidad del producto, usted
              deber&aacute; ponerse en contacto con el tercero que ofrece los
              mismos. &nbsp;
            </p>
            <p>
              Si usted decide comprar un producto, deber&aacute; seleccionar el
              producto haciendo click en la imagen del producto. De esta forma
              usted ser&aacute; redirigido a la p&aacute;gina web donde se
              ofrece dicho producto. Tanto para el acceso como para la compra en
              el sitio web del tercero, quedar&aacute;s sujeto a los
              t&eacute;rminos y de uso y privacidad de dicha p&aacute;gina.
              &nbsp;En consecuencia Armoire no se hace responsable del contrato
              final entre usted y el tercero con el que concreta la compra.
            </p>
            <p>
              Asimismo, para poder ser redirigido a la p&aacute;gina web donde
              se ofrece dicho producto, usted deber&aacute; registrarse como
              usuario de Armoire.
            </p>

            <h2>REGISTRO DE USUARIO</h2>

            <p>
              El acceso al sitio web de Armoire tiene car&aacute;cter gratuito
              para todos los usuarios y no requiere previa inscripci&oacute;n o
              registro. Sin embargo, para hacer uso de algunas funcionalidades
              del sitio web, como por ejemplo la posibilidad de ser redirigido a
              la p&aacute;gina web donde se ofrece el producto seleccionado, el
              usuario deber&aacute; registrarse, de acuerdo a lo que se
              dir&aacute; a continuaci&oacute;n.
            </p>
            <p>
              El usuario debe leer y aceptar todas las condiciones aqu&iacute;
              establecidas, as&iacute; como los otros documentos que se
              agreguen, previo a su registro como usuario de Armoire.
            </p>
            <p id="h.gjdgxs">
              Usted deber&aacute; completar de manera exacta y precisa los datos
              requeridos tales como correo electr&oacute;nica, nombre y
              apellido. Todo el proceso de registro de usuario ser&aacute;
              tratado con elevados est&aacute;ndares de seguridad y
              confidencialidad de acuerdo con lo previsto en la ley 18.331. Su
              informaci&oacute;n personal se procesa y almacena en servidores
              que mantienen altos est&aacute;ndares de seguridad.
            </p>
            <p>
              Al registrarse en Armoire se le solicitar&aacute; acceder a su
              cuenta personal de Google, consecuentemente, Usted declara que
              tiene derecho a revelar la informaci&oacute;n de inicio de
              sesi&oacute;n de su Cuenta de Google a Armoire y/o conceder el
              acceso a Armoire a determinados datos, sin que ello suponga un
              incumplimiento por su parte de ninguno de los t&eacute;rminos y
              condiciones que rigen el uso de dicha cuenta. Adicionalmente, su
              relaci&oacute;n con proveedores externos de servicio, se rige
              exclusivamente por los acuerdos que tenga suscritos con dichos
              proveedores.
            </p>
            <p>
              Armoire se reserva el derecho de suspender o &nbsp;bloquear
              &nbsp;temporal o definitivamente a aquellos usuarios que incumplan
              los presentes T&eacute;rminos y Condiciones, sin que implique
              ning&uacute;n tipo de responsabilidad para Armoire.
            </p>

            <h2>PROTECCION DE DATOS PERSONALES</h2>

            <p>
              4.1. &nbsp;Armoire recoge y almacena autom&aacute;ticamente
              ciertos datos de navegaci&oacute;n de los usuarios y recolecta
              determinados datos personales como correo electr&oacute;nico,
              nombre y apellido, con la finalidad de mantener un registro de los
              usuarios que se registran y utilizan el sitio web.
            </p>

            <p>
              4.2. Todos los datos personales son recolectados y almacenados en
              servidores ubicados f&iacute;sicamente en los Estados Unidos de
              Am&eacute;rica. Al aceptar estos t&eacute;rminos y condiciones,
              usted presta su consentimiento inequ&iacute;voco para que Armoire
              pueda transferir sus datos personales a cualquier pa&iacute;s del
              mundo. Asimismo, garantizamos que los datos personales
              transferidos al extranjero ser&aacute;n protegidos. En caso de
              transferencia de datos personales, el receptor de los datos
              personales asumir&aacute; las mismas obligaciones que corresponden
              al responsable que transfiere dichos datos.
            </p>

            <p>
              4.3. Armoire dispone de herramientas para proteger sus datos
              personales de accesos no autorizados por parte de terceros.
              Tomamos las medidas de seguridad apropiadas para evitar el acceso
              no autorizado, la alteraci&oacute;n, la divulgaci&oacute;n o la
              destrucci&oacute;n de sus datos.
            </p>
            <p>
              4.4. Derechos de acceso, rectificaci&oacute;n, cancelaci&oacute;n
              y oposici&oacute;n de sus datos personales: En cumplimiento de lo
              dispuesto por las disposiciones de la Ley N&deg;18.331, el Decreto
              Reglamentario N&deg;414/009 y dem&aacute;s normativa aplicable
              concordante y modificativa, se comunica que todo titular de datos
              personales que previamente acredite su identidad, tiene derecho a
              obtener toda la informaci&oacute;n que sobre s&iacute; mismo se
              halle en bases de datos p&uacute;blicas o privadas, as&iacute;
              como solicitar la rectificaci&oacute;n, actualizaci&oacute;n,
              inclusi&oacute;n o supresi&oacute;n de los datos personales que le
              corresponda incluidos en una base de datos, al constatarse error o
              falsedad o exclusi&oacute;n en la informaci&oacute;n de la que es
              titular. Usted puede ejercer los derechos mencionados
              precedentemente, mediante el env&iacute;o de una solicitud por
              correo electr&oacute;nico a la direcci&oacute;n &nbsp;
              <a href="mailto:armoire.uy@gmail.com">armoire.uy@gmail.com</a>. En
              su solicitud deber&aacute; indicar su nombre completo y acreditar
              su identidad. Asimismo, en caso que realice la solicitud en
              representaci&oacute;n de un tercero deber&aacute; acreditar sus
              facultades de representaci&oacute;n.
            </p>
            <p></p>

            <h2>
              CONSENTIMIENTO PARA LA UTILIZACI&Oacute;N DE SU INFORMACI&Oacute;N
            </h2>

            <p></p>
            <p>
              De conformidad con la Ley N&ordm; 18.331, de 11 de agosto de 2008,
              de Protecci&oacute;n de Datos Personales y Acci&oacute;n de Habeas
              Data (LPDP), los datos suministrados por usted quedar&aacute;n
              incorporados en la base de datos Registro de Usuarios, la cual
              ser&aacute; procesada exclusivamente con la finalidad de mantener
              un registro de usuarios de Armoire y adicionalmente para hacerle
              llegar propuestas v&iacute;a correo electr&oacute;nico, a los
              efectos de enviarle informaci&oacute;n que pueda ser de su
              inter&eacute;s, incluyendo publicidad e informaci&oacute;n sobre
              ofertas y promociones. &nbsp;Al aceptar los presentes
              t&eacute;rminos y condiciones usted presta su consentimiento para
              la utilizaci&oacute;n de su informaci&oacute;n con los fines
              mencionados precedentemente, de conformidad con las condiciones
              aqu&iacute; establecidas y las leyes aplicables. &nbsp;
            </p>
            <p>
              Los datos personales ser&aacute;n tratados con el grado de
              protecci&oacute;n adecuado, tom&aacute;ndose las medidas de
              seguridad necesarias para evitar su alteraci&oacute;n,
              p&eacute;rdida, tratamiento o acceso no autorizado por parte de
              terceros. El responsable de la Base de datos es Armoire y la
              direcci&oacute;n donde podr&aacute; ejercer los derechos de
              acceso, rectificaci&oacute;n, actualizaci&oacute;n,
              inclusi&oacute;n o supresi&oacute;n, es &nbsp;
              <a href="mailto:armoire.uy@gmail.com">armoire.uy@gmail.com</a>.
            </p>
            <p>
              Todos los datos personales son recolectados y almacenados en
              servidores ubicados f&iacute;sicamente en los Estados Unidos de
              Am&eacute;rica, al requerir servidores y servicios en la nube que
              pueden ser provistos por terceros. Mediante el presente, usted
              presta su consentimiento inequ&iacute;voco para que Armoire pueda
              transferir sus datos personales a cualquier pa&iacute;s del mundo.
              Garantizamos que los datos personales transferidos al extranjero
              ser&aacute;n protegidos. En caso de transferencia de datos
              personales, el receptor de los datos personales asumir&aacute; las
              mismas obligaciones que corresponden al responsable que transfiere
              los mismos
            </p>

            <h2>ENLACES</h2>

            <p>
              Armoire &nbsp;puede incluir un enlace u ofrecer otra forma de
              acceso a sitios de terceros los cuales &nbsp;tienen sus propias
              pol&iacute;ticas de privacidad. Armoire no controla, ni es
              responsable de los sitios de terceros, ni de su contenido o
              productos. Sugerimos leer y revisar las pol&iacute;ticas de
              privacidad de cada p&aacute;gina web para un uso responsable de
              las mismas. Armoire no ser&aacute; responsable por los contenidos
              ni productos ofrecidos por los mismos, ni por da&ntilde;os o
              p&eacute;rdidas ocasionadas por su utilizaci&oacute;n de los
              mismos, sean causadas directa o indirectamente.
            </p>

            <h2>PROPIEDAD INTELECTUAL</h2>

            <p>
              Las marcas y logos aqu&iacute; mostrados son propiedad de sus
              respectivos propietarios y son utilizadas &uacute;nicamente con
              fines enunciativos, a los efectos de que usted pueda visualizar
              determinado contenido. &nbsp;Si Usted considera que la presente
              p&aacute;gina web o determinado contenido de la p&aacute;gina
              vulnera o atenta de alg&uacute;n modo derechos de propiedad
              intelectual y/o industrial de terceros, por favor no dude en
              comunicarse con nosotros, envi&aacute;ndonos una
              comunicaci&oacute;n al siguiente correo electr&oacute;nico: &nbsp;
              <a href="mailto:armoire.uy@gmail.com">armoire.uy@gmail.com</a>.
            </p>

            <h2>
              RESPONSABILIDAD
              <br />
            </h2>

            <p>
              Armoire pone a disposici&oacute;n de los Usuarios un espacio que
              les permite visualizar y ponerse en contacto con terceros que
              ofrecen diversos productos. Armoire no es el propietario de los
              art&iacute;culos ofrecidos por terceros, no tiene posesi&oacute;n
              de ellos, y consecuentemente tampoco los ofrece en venta.
              Asimismo, Armoire no interviene en el perfeccionamiento de las
              operaciones realizadas entre los Usuarios y los terceros, por ello
              no ser&aacute; responsable respecto de la existencia, calidad,
              cantidad, estado, integridad o legitimidad de los bienes
              ofrecidos. Cada Usuario declara conocer y aceptar ser el exclusivo
              responsable por las compras que efect&uacute;a.
            </p>
            <p>
              Asimismo Armoire no tiene ninguna participaci&oacute;n durante
              todo el tiempo en que el producto se publica para la venta, ni en
              la posterior negociaci&oacute;n y perfeccionamiento del contrato
              definitivo entre el usuario y el tercero, por lo tanto Armoire no
              ser&aacute; responsable por el efectivo cumplimiento de las
              obligaciones asumidas por los Usuarios en el perfeccionamiento de
              la operaci&oacute;n. En ning&uacute;n caso Armoire ser&aacute;
              responsable por lucro cesante, o por cualquier otro da&ntilde;o
              y/o perjuicio que haya podido sufrir el Usuario.
            </p>
            <p>
              En caso que uno o m&aacute;s Usuarios inicien cualquier tipo de
              reclamo o acciones legales contra un tercero, el Usuarios
              involucrado exime de toda responsabilidad a Armoire y a sus
              representantes.
            </p>

            <h2>JURISDICCI&Oacute;N Y LEY APLICABLE</h2>

            <p>
              Mediante el uso del sitio web de Armoire, el usuario queda sujeto
              a las leyes vigentes en la Rep&uacute;blica Oriental del Uruguay.
            </p>
            <p>
              Cualquier controversia derivada del presente acuerdo, su
              existencia, validez, interpretaci&oacute;n, alcance o
              cumplimiento, ser&aacute; sometida ante los tribunales de
              Montevideo de la Rep&uacute;blica Oriental del Uruguay. &nbsp;
            </p>

            <h2>COMUNICACIONES</h2>

            <p>
              Armoire determina que toda comunicaci&oacute;n que deba efectuarse
              bajo estos t&eacute;rminos y condiciones, deber&aacute; realizarse
              por escrito a la cuenta de correo electr&oacute;nico&nbsp;
              <a href="mailto:armoire.uy@gmail.com">armoire.uy@gmail.com</a>.
            </p>
            <p>
              Asimismo, si tiene alguna duda o sugerencia sobre los presentes
              T&eacute;rminos y condiciones, o sobre el sitio web, puede
              contactarnos a la direcci&oacute;n de correo
              electr&oacute;nico:&nbsp;
              <a href="mailto:armoire.uy@gmail.com">armoire.uy@gmail.com</a>.
            </p>

            <h2>MODIFICACIONES</h2>

            <p>
              Armoire se reserva el derecho de modificar los presentes
              t&eacute;rminos y condiciones en cualquier momento haciendo
              p&uacute;blicos en el sitio los t&eacute;rminos modificados.
              Ser&aacute; responsabilidad del usuario verificar los
              t&eacute;rminos y condiciones siempre que efect&uacute;e un nuevo
              acceso en la p&aacute;gina web.
            </p>
          </div>
        </div>
      </main>
    );
  }
}
