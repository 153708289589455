import React, { useRef } from 'react';
import {
  InstantSearch,
  HierarchicalMenu,
  RefinementList,
  ClearRefinements,
  Panel,
  Configure,
  SearchBox,
  SortBy,
} from 'react-instantsearch-dom';
import Login from './Login.js';
import algoliasearch from 'algoliasearch/lite';
import InfiniteHits from './InfiniteHits';
import { firebaseApp } from '../firebaseConfig';
import withFirebaseAuth from 'react-with-firebase-auth';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import {
  ClearFiltersMobile,
  NoResults,
  ResultsNumberMobile,
  SaveFiltersMobile,
} from '../widgets';
import withURLSync from '../URLSync';
import { RangeInput } from 'react-instantsearch-dom';
import '../widgets/Pagination.css';
import AlgoliaSvg from '../AlgoliaSvg';
import ScrollToTop from 'react-scroll-up';
import ExpandLessOutlinedIcon from '@material-ui/icons/ExpandLessOutlined';

const indexName = 'items';

const Home = (props) => {
  const containerRef = useRef(null);
  const headerRef = useRef(null);
  const [modal, setModal] = React.useState(true);

  var searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY,
    {
      headers: {
        'X-Algolia-UserToken': props.user ? props.user.uid : '12345',
      },
    }
  );

  function openFilters() {
    document.body.classList.add('filtering');
    window.scrollTo(0, 0);
    window.addEventListener('keyup', onKeyUp);
    window.addEventListener('click', onClick);
  }

  function closeFilters() {
    document.body.classList.remove('filtering');
    containerRef.current.scrollIntoView();
    window.removeEventListener('keyup', onKeyUp);
    window.removeEventListener('click', onClick);
  }

  function onKeyUp(event) {
    if (event.key !== 'Escape') {
      return;
    }

    closeFilters();
  }

  function onClick(event) {
    if (event.target !== headerRef.current) {
      return;
    }

    closeFilters();
  }

  return props.user !== undefined ? (
    <InstantSearch
      searchClient={searchClient}
      indexName={indexName}
      searchState={props.searchState}
      createURL={props.createURL}
      onSearchStateChange={props.onSearchStateChange}
    >
      <header className="header" ref={headerRef}>
        <p className="header-logo">
          <AlgoliaSvg />
        </p>

        <SearchBox
          translations={{
            placeholder: 'Producto, tienda, marca, material, color, …',
          }}
          submit={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 18 18"
            >
              <g
                fill="none"
                fillRule="evenodd"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.67"
                transform="translate(1 1)"
              >
                <circle cx="7.11" cy="7.11" r="7.11" />
                <path d="M16 16l-3.87-3.87" />
              </g>
            </svg>
          }
        />
        <Login />
        <div
          className="modal2-background"
          style={{ display: props.user == null && modal ? 'auto' : 'none' }}
          data-mobile-placement="center"
        >
          <div
            className="sign-in-sign-up-modal-wrapper uncloseable sign-in-sign-up-modal-wrapper--uncloseable active"
            data-google-login-enabled=""
            data-modal2="sign-in"
          >
            <div
              aria-label="Member Sign In"
              aria-modal="true"
              className="sign-in-sign-up-modal-wrapper__content js-sign-in-modal-content modal2-window"
              role="dialog"
            >
              <div className="modal-header">
                <div className="armoire-logo">
                  <img
                    alt="Armoire:"
                    className="armoire-logo-img"
                    src="/logo.png"
                    width="180"
                  ></img>
                </div>
                <div className="armoire-logo-tagline">
                  Todos tus outfits en un solo lugar.
                </div>
              </div>
              <div className="modal-body">
                <div className="sign-in-body">
                  <div className="form-title">Ingreso de miembros</div>
                  <div className="formtastic-form__action user-form__facebook-action">
                    <div
                      aria-label="Sign in with Google"
                      className="oauth-sign-in-button google-sign-in-button"
                      data-google-login="true"
                      role="button"
                      onClick={() =>
                        typeof window.orientation !== 'undefined'
                          ? firebaseAppAuth.signInWithRedirect(
                              providers['googleProvider']
                            )
                          : firebaseAppAuth.signInWithPopup(
                              providers['googleProvider']
                            )
                      }
                    >
                      <span className="oauth-sign-in-button__logo"></span>

                      <span>Continuar con Google</span>
                    </div>
                  </div>
                  <div className="anonymous-login-description">
                    Puedes acceder anónimamente. Pero no podras acceder a los
                    sitios de las tiendas ni guardar tus favoritos.
                  </div>
                  <ol>
                    <li
                      className="formtastic-form__action formtastic-form__action--submit user-form__action user-form__action--submit"
                      id="user_submit_action"
                    >
                      <input
                        aria-label="Log In"
                        className="button button--primary button--featured button--capped-full-width form-field__submit"
                        data-disable-with="..."
                        name="commit"
                        type="submit"
                        value="Continuar anónimamente"
                        onClick={() => setModal(false)}
                      ></input>
                    </li>
                  </ol>
                </div>
                <div class="reg-gate-fine-print">
                  Al unirse a Armoire, acepta los&nbsp;
                  <a
                    aria-label="Términos de servicio (se abre en una nueva ventana)"
                    class="reg-gate-fine-print-link"
                    href="/terminos"
                    target="_blank"
                    tabindex="0"
                  >
                    Términos de servicio
                  </a>
                  {/* <a
                    aria-label="Política de cookies (se abre en una nueva ventana)"
                    class="reg-gate-fine-print-link"
                    href="/privacy"
                    target="_blank"
                    tabindex="0"
                  >
                    Política de cookies
                  </a> */}
                  &nbsp;y recibir correos electrónicos promocionales.
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <Configure
        attributesToSnippet={['description:10']}
        snippetEllipsisText="…"
        removeWordsIfNoResults="allOptional"
        clickAnalytics
      />

      <main className="container" ref={containerRef}>
        <div className="container-wrapper">
          <section className="container-filters" onKeyUp={onKeyUp}>
            <div className="container-header">
              <h2>Filtros</h2>

              <div className="clear-filters" data-layout="desktop">
                <ClearRefinements
                  translations={{
                    reset: (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="11"
                          height="11"
                          viewBox="0 0 11 11"
                        >
                          <g fill="none" fillRule="evenodd" opacity=".4">
                            <path d="M0 0h11v11H0z" />
                            <path
                              fill="#000"
                              fillRule="nonzero"
                              d="M8.26 2.75a3.896 3.896 0 1 0 1.102 3.262l.007-.056a.49.49 0 0 1 .485-.456c.253 0 .451.206.437.457 0 0 .012-.109-.006.061a4.813 4.813 0 1 1-1.348-3.887v-.987a.458.458 0 1 1 .917.002v2.062a.459.459 0 0 1-.459.459H7.334a.458.458 0 1 1-.002-.917h.928z"
                            />
                          </g>
                        </svg>
                        Limpiar filtros
                      </>
                    ),
                  }}
                />
              </div>

              <div className="clear-filters" data-layout="mobile">
                <ResultsNumberMobile />
              </div>
            </div>

            <div className="container-body">
              <Panel header="Categorias">
                <HierarchicalMenu
                  limit={999}
                  attributes={[
                    'hierarchicalCategories.lvl0',
                    'hierarchicalCategories.lvl1',
                    'hierarchicalCategories.lvl2',
                  ]}
                />
              </Panel>

              <Panel header="Precio">
                <RangeInput
                  attribute="sell_price"
                  min={1}
                  precision={0}
                  translations={{
                    submit: 'OK',
                    separator: 'a',
                  }}
                />
              </Panel>

              <Panel header="Tiendas">
                <RefinementList
                  attribute="store"
                  searchable={true}
                  limit={15}
                  showMore={true}
                  showMoreLimit={999}
                  translations={{
                    showMore(expanded) {
                      return expanded ? 'Mostrar menos' : 'Mostrar mas';
                    },
                    noResults: 'No hay resultados',
                    placeholder: 'Busca tiendas...',
                  }}
                />
              </Panel>
            </div>
          </section>

          <footer className="container-filters-footer" data-layout="mobile">
            <div className="container-filters-footer-button-wrapper">
              <ClearFiltersMobile containerRef={containerRef} />
            </div>

            <div className="container-filters-footer-button-wrapper">
              <SaveFiltersMobile onClick={closeFilters} />
            </div>
          </footer>
        </div>

        <section className="container-results">
          <header className="container-header container-options">
            <SortBy
              className="container-option"
              defaultRefinement={indexName}
              items={[
                {
                  label: 'Nuevos Arribos',
                  value: `${indexName}_created_at`,
                },
                {
                  label: 'Mas Populares',
                  value: `${indexName}`,
                },
                {
                  label: '% Descuento',
                  value: `${indexName}_discount`,
                },
                {
                  label: '$ Descuento',
                  value: `${indexName}_total_discount`,
                },
                {
                  label: 'Menor precio',
                  value: `${indexName}_price_asc`,
                },
                {
                  label: 'Mayor precio',
                  value: `${indexName}_price_desc`,
                },
                // {
                //   label: 'Views descending',
                //   value: `${indexName}_views`,
                // },
              ]}
            />

            {/* <HitsPerPage
              className="container-option"
              items={[
                {
                  label: '16 hits per page',
                  value: 16,
                },
                {
                  label: '32 hits per page',
                  value: 32,
                },
                {
                  label: '64 hits per page',
                  value: 64,
                },
              ]}
              defaultRefinement={16}
            /> */}
          </header>

          <InfiniteHits minHitsPerPage={16} />
          <NoResults />
          <ScrollToTop showUnder={160}>
            <button>
              <ExpandLessOutlinedIcon />{' '}
            </button>
          </ScrollToTop>
        </section>
      </main>

      <aside data-layout="mobile">
        <button
          className="filters-button"
          data-action="open-overlay"
          onClick={openFilters}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 14">
            <path
              d="M15 1H1l5.6 6.3v4.37L9.4 13V7.3z"
              stroke="#fff"
              strokeWidth="1.29"
              fill="none"
              fillRule="evenodd"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Filtros
        </button>
      </aside>
    </InstantSearch>
  ) : null;
};

const firebaseAppAuth = firebaseApp.auth();

const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
};

export default withURLSync(
  withFirebaseAuth({
    providers,
    firebaseAppAuth,
  })(Home)
);
