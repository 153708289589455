import PropTypes from 'prop-types';
import React from 'react';
import { Highlight } from 'react-instantsearch-dom';
import Likes from './Likes';

import Flickity from 'react-flickity-component';
import '../flickity.css';
import { formatNumber } from './utils';
import { Link } from 'react-router-dom';
// import { db } from '../firebaseConfig';
import firebase from 'firebase/app';
import 'firebase/auth';
import { firebaseApp } from '../firebaseConfig';
import withFirebaseAuth from 'react-with-firebase-auth';
import aa from 'search-insights';
import { connectHitInsights } from 'react-instantsearch-dom';

aa('init', {
  appId: process.env.REACT_APP_ALGOLIA_APP_ID,
  apiKey: process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY,
});

const flickityOptions = {
  wrapAround: false,
  cellAlign: 'center',
  fade: true,
  pageDots: false,
};

// const DATE_UNITS = {
//   day: 86400,
//   hour: 3600,
//   minute: 60,
//   second: 1,
// };

// const getSecondsDiff = (timestamp) => (Date.now() - timestamp) / 1000;
// const getUnitAndValueDate = (secondsElapsed) => {
//   for (const [unit, secondsInUnit] of Object.entries(DATE_UNITS)) {
//     if (secondsElapsed >= secondsInUnit || unit === 'second') {
//       const value = Math.floor(secondsElapsed / secondsInUnit) * -1;
//       return { value, unit };
//     }
//   }
// };

// const getTimeAgo = (timestamp) => {
//   const rtf = new Intl.RelativeTimeFormat();

//   const secondsElapsed = getSecondsDiff(timestamp);
//   const { value, unit } = getUnitAndValueDate(secondsElapsed);
//   return rtf.format(value, unit);
// };

const RenderHitImages = (props) => {
  const hit = props.hit;
  return (
    <div>
      <Flickity options={flickityOptions}>
        <div className="hit-image-container" key={hit.image}>
          {props.props.user ? (
            <Link
              target="_newblank"
              to={{
                pathname: `/product/${hit.objectID}`,
              }}
            >
              <img
                src={hit.image}
                alt={hit.name}
                className="hit-image"
                onError={(i) =>
                  (i.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.style.display =
                    'none')
                }
                referrerPolicy="no-referrer"
              />
            </Link>
          ) : (
            <img
              src={hit.image}
              alt={hit.name}
              className="hit-image"
              onClick={() =>
                alert(
                  'Para acceder al sitio seleccionado, deberás registrarte previamente como usuario de Armoire.'
                )
              }
              onError={(i) =>
                (i.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.style.display =
                  'none')
              }
              referrerPolicy="no-referrer"
            />
          )}
        </div>
      </Flickity>
    </div>
  );
};

const Hit = (props) => {
  const hit = props.hit;
  // const [isClick, setClick] = React.useState(false);

  // const [views, setViews] = React.useState([]);
  // React.useEffect(() => {
  //   db.collection('items')
  //     .doc(hit.objectID)
  //     .collection('views')
  //     .onSnapshot((snapshot) => {
  //       setViews(snapshot.docs.map((doc) => doc.id));
  //     });
  // }, [hit.objectID]);

  return (
    <article className="hit">
      <RenderHitImages hit={hit} props={props} />

      <div className="hit-info-container">
        <div className="hit-store">
          <img
            src={`https://s2.googleusercontent.com/s2/favicons?domain_url=${hit.store_url}`}
            alt=""
          ></img>
          <span className="hit-store__text">{hit.store}</span>
        </div>

        <span className="hit-category">{hit.categories[0]}</span>

        <h1>
          <Highlight attribute="name" tagName="mark" hit={hit} />
        </h1>
        {/* <div className="hit-description">
                <Snippet attribute="description" tagName="mark" hit={hit} />
            </div> */}

        {/* <div class="sizes">
          {hit.available_sizes
            ? `Talle: ${hit.available_sizes.join(' | ')}`
            : null}
        </div> */}
        <footer className="hit-footer">
          <div style={{ marginRight: '5px' }}>
            <span>$</span> <span>{formatNumber(hit.sell_price)} </span>{' '}
          </div>

          {hit.total_discount ? (
            <div
              style={{
                textDecoration: 'line-through',
                marginRight: '5px',
                color: '#999',
              }}
            >
              <span>$</span> <span>{formatNumber(hit.list_price)}</span>{' '}
            </div>
          ) : null}
          {hit.total_discount ? (
            <div style={{ marginRight: '5px' }}>
              <span style={{ color: 'red' }}>
                {'- '}
                {hit.discount}%
              </span>
            </div>
          ) : null}
        </footer>

        <Likes
          id={hit.objectID}
          likes={hit.likes ? hit.likes : 0}
          insights={props.insights}
        />
        {/* <div className="hit__views">
          <span>{views.length} views</span>
        </div> */}
        {/* <time datetime={hit.created_at}>
          {getTimeAgo(new Date(hit.created_at))}
        </time> */}
      </div>
    </article>
  );
};

const HitWithInsights = connectHitInsights(aa)(Hit);

Hit.propTypes = {
  hit: PropTypes.object.isRequired,
};

const firebaseAppAuth = firebaseApp.auth();

const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
};

export default withFirebaseAuth({
  providers,
  firebaseAppAuth,
})(HitWithInsights);
