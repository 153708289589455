import React, { Component } from 'react';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import PropTypes from 'prop-types';
import HitWithInsights from './Hit';

class InfiniteHits extends Component {
  static propTypes = {
    hits: PropTypes.arrayOf(PropTypes.object).isRequired,
    hasMore: PropTypes.bool.isRequired,
    refine: PropTypes.func.isRequired,
  };

  sentinel = null;

  onSentinelIntersection = (entries) => {
    const { hasMore, refine } = this.props;

    entries.forEach((entry) => {
      if (entry.isIntersecting && hasMore) {
        refine();
      }
    });
  };

  componentDidMount() {
    this.observer = new IntersectionObserver(this.onSentinelIntersection);

    this.observer.observe(this.sentinel);
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  render() {
    const { hits } = this.props;
    return (
      <div className="ais-Hits">
        <ul className="ais-Hits-list">
          {hits.map((hit) => (
            <li key={hit.objectID} className="ais-Hits-item">
              <HitWithInsights hit={hit} />
            </li>
          ))}
          <li className="ais-Hits-sentinel" ref={(c) => (this.sentinel = c)} />
        </ul>
      </div>
    );
  }
}

export default connectInfiniteHits(InfiniteHits);
