import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

var firebaseConfig = {
  apiKey: 'AIzaSyDMZXK-m73mX24ja5Gt1VDatLXwZR0QYO4',
  authDomain: 'armoire.com.uy',
  projectId: 'pineapple-307123',
  storageBucket: 'pineapple-307123.appspot.com',
  messagingSenderId: '314830273928',
  appId: '1:314830273928:web:a5d25bb397d79e0b830e3d',
  measurementId: 'G-0S816P47KD',
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();

export { db, auth, storage, firebaseApp };
