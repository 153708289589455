import React from 'react';
import { connectToggleRefinement } from 'react-instantsearch-dom';

const ToggleRefinement = ({
  currentRefinement,
  label,
  count,
  refine,
  createURL,
}) => (
  <a
    aria-label="Likes. List of your favorites."
    className="icon-button icon-button--obsessed"
    href={createURL(!currentRefinement)}
    style={{ fontWeight: currentRefinement ? 'bold' : '' }}
    onClick={(event) => {
      event.preventDefault();
      refine(!currentRefinement);
    }}
  >
    {null}
  </a>
);

const CustomToggleRefinement = connectToggleRefinement(ToggleRefinement);
export default CustomToggleRefinement;
