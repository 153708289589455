import { configure } from 'instantsearch.js/es/widgets';
const parameters = new URLSearchParams(window.location.search);

const page = parameters.get('page') || '';

const $pageLink = document.querySelector(`[data-page="${page}"]`);

if ($pageLink) {
    $pageLink.parentNode.classList.add('active');
}

const context = ((page) => {
    switch (page) {
        case '':
            return '';
        case 'Mujer':
            return 'Mujer';
        case 'Hombre':
            return 'Hombre';
        case 'Ninos':
            return 'Ninos';
        default:
            return '';
    }
})(page);

export const configuration = configure({
    attributesToSnippet: ['description:10'],
    snippetEllipsisText: '…',
    removeWordsIfNoResults: 'allOptional',
    ruleContexts: context,
});
