import React, { Component } from 'react';
import withFirebaseAuth from 'react-with-firebase-auth';
import firebase from 'firebase/app';
import 'firebase/auth';
import { firebaseApp } from '../firebaseConfig';
import CustomToggleRefinement from './LikesToggle';

class Login extends Component {
  render() {
    const { user, signOut } = this.props;

    return (
      <div className="login">
        {user ? (
          <div className="nav--controls">
            <CustomToggleRefinement
              attribute={'user_likes'}
              label={''}
              value={user ? user.uid : '12345'}
            />
            <span
              aria-label="Sign Out"
              className="icon-button icon-button--sign-out"
              onClick={signOut}
            ></span>
          </div>
        ) : (
          <div>
            <span
              style={{
                display: 'flex',
                alignContent: 'center',
                flexWrap: 'wrap',
                flexDirection: 'row',
                color: 'black',
                fontSize: 12.5,
              }}
            >
              Iniciar sesion
            </span>
            <span
              aria-label="Sign In"
              className="icon-button icon-button--sign-in"
              onClick={() =>
                typeof window.orientation !== 'undefined'
                  ? firebaseAppAuth.signInWithRedirect(
                      providers['googleProvider']
                    )
                  : firebaseAppAuth.signInWithPopup(providers['googleProvider'])
              }
            ></span>
          </div>
        )}
      </div>
    );
  }
}

const firebaseAppAuth = firebaseApp.auth();

const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
};

export default withFirebaseAuth({
  providers,
  firebaseAppAuth,
})(Login);
