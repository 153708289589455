import React from 'react';
import '../flickity.css';
import algoliasearch from 'algoliasearch';
import { useParams } from 'react-router-dom';
import { db } from '../firebaseConfig';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { firebaseApp } from '../firebaseConfig';
import withFirebaseAuth from 'react-with-firebase-auth';

const publishReferrer = (data) => {
  const { item, user } = data;
  var batch = db.batch();
  const referrersUser = db
    .collection('refereritems')
    .doc(item.objectID)
    .collection('users')
    .doc(user.uid);

  batch.set(referrersUser, {
    currentTime: firebase.firestore.FieldValue.serverTimestamp(),
  });

  batch.commit().then(() => {
    window.location.href = item.link;
  });
};

const firebaseAppAuth = firebaseApp.auth();

const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
};

const Reff = (props) => {
  const params = useParams();
  const user = props.user;
  const [item, setItem] = React.useState(null);
  // const [referer, setReferer] = React.useState(null);

  var index = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY,
    {
      headers: {
        'X-Algolia-UserToken': props.user ? props.user.uid : '12345',
      },
    }
  ).initIndex('items');

  React.useEffect(() => {
    index.getObject(params.id).then((item) => setItem(item));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (item && user) {
    publishReferrer({ item: item, user: user });
  }
  return null;
};

export default withFirebaseAuth({
  providers,
  firebaseAppAuth,
})(Reff);
