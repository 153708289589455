import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Home from './Home';
import Terminos from './Terminos';
import Reff from './Referer';
// import LikeSection from './LikeSection';
// import History from './History';

import '../Theme.css';
// import './Trending.css';
import '../App.css';
import '../App.mobile.css';

export default function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/search" component={Home} />
        <Route path="/terminos" component={Terminos} />
        {/* <Route path="/likes" component={LikeSection} /> */}
        {/* <Route path="/history" component={History} /> */}
        <Route path="/product/:id" component={Reff} />
      </Switch>
    </Router>
  );
}
